import { ReactElement, useMemo, useEffect } from 'react'
import { SearchScaffoldViewModel, useGetDiscountedProductsQuery, DiscountedProductResult } from '@web/shared-data-access-queries'
import { ProductResultCard } from '@web/product-navigation-feature'
import { LoadingIndicator, Breadcrumbs, BreadcrumbViewModel, Button } from '@web/shared-ui-components'
import { useLocation } from 'react-router-dom'

interface DiscountedProductsInGroupProps {
  groupId: string
}

function DiscountedProductsInGroup ({ groupId }: DiscountedProductsInGroupProps): ReactElement | null {
  const numericGroupId = parseInt(groupId)
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const searchSource = Array.from(params).filter(x => x[0].toLowerCase() === 'searchsource')?.[0]?.[1]
  const { loading, data: newData, previousData } = useGetDiscountedProductsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      groupuid: numericGroupId,
      searchsource: searchSource
    }
  })
  const data = ((loading && newData == null) ? previousData : newData)?.getDiscountedProducts

  const discountedProducts = useMemo(() => {
    const results = data?.results
    if (results != null && results.length === 1) {
      window.location.replace(`/products/details?iid=${results[0].Id}&gid=${groupId}&recommendationSource=Web_Overstock`)
    }
    return data?.results
  }, [data?.results])

  const showLoading = useMemo(() => loading || (data != null && data?.results.length <= 1), [data?.results, loading])

  const breadcrumbs: BreadcrumbViewModel[] = [
    { EncodedText: 'Deals', Url: '/deals' },
    { EncodedText: 'Items on sale' }
  ]

  const searchResultsScaffold: SearchScaffoldViewModel = useMemo(() => window.stullerMetadata?.searchResultsScaffold, [])
  const pageHeader = 'Discounted Products In Group'

  useEffect(() => {
    if (searchResultsScaffold.pageTitle != null) {
      window.document.title = searchResultsScaffold.pageTitle
    }
  }, [searchResultsScaffold.pageTitle])

  // scroll to the top of the page every time the component rerenders
  useEffect(() => {
    window.document.body.scrollIntoView()
  })

  return (
    <LoadingIndicator loading={showLoading}>
      {!showLoading &&
        <>
          <div className='u-flex-grid-row'>
            <div className='col-12'>
              <Breadcrumbs crumbs={breadcrumbs} allowHtml />
            </div>
            <div className='col-12'>
              <h1 className='searchResultsForLabel' dangerouslySetInnerHTML={{ __html: pageHeader }} />
            </div>
          </div>
          <div className='productResults'>
            <div id='results'>
              <div className='productResultGrid u-flex-grid-row pt-4'>
                {discountedProducts?.map((productResult, index) =>
                  <ProductResultCard<DiscountedProductResult>
                    key={`${productResult?.Id.toString() ?? index}-productCard`}
                    productResult={productResult}
                    searchSource={null}
                    showDiscounts
                    onProductSelected={() => null}
                    position={index}
                    className='u-flex-grid-col-xl-3 u-flex-grid-col-lg-4 u-flex-grid-col-sm-6 col-12'
                    directToPDP
                    groupId={numericGroupId}
                  />
                )}
              </div>
            </div>
          </div>
          <div className='col-xs-12'>
            <div className='col-sm-6 col-sm-offset-3 text-center c-bg-gray-lt-3 py-4 mt-4 position-relative u-border-radius-medium'>
              <div className='f-roboto-light pb-5 view-all-title' style={{ fontSize: '22px' }}>
                Want to view items not included in the current sale?
              </div>
              <Button tag='a' color='primary' href={`/products/${numericGroupId}/?groupId=${numericGroupId}`}>
                View All Available Items
              </Button>
            </div>
          </div>
        </>}
    </LoadingIndicator>
  )
}

export { DiscountedProductsInGroup }
