import { ReactElement, useEffect, useState, useMemo } from 'react'
import { useLocation, Switch, Route } from 'react-router-dom'
import {
  useGetSearchNavigationQuery,
  SearchScaffoldViewModel
} from '@web/shared-data-access-queries'
import {
  BrowserRedirect,
  EmbeddedSvgLoader,
  LoadingIndicator,
  NotFoundPage,
  DynamicErrorPage,
  CmsContentProvider,
  TrackComponentLoad
} from '@web/shared-ui-components'
import { ContentOnlyView, ProductNavigationInner, Permalink, ProductNavigationContext } from '@web/product-navigation-feature'
import { CustomApolloErrorNames } from '@web/shared-data-access-apollo'
import { ROUTES, SearchPresentationContext } from '@web/product-navigation-types'
import { DiscountedProductsInGroup } from 'libs/deals/feature/DiscountedProductsInGroup'
import { useUserContext } from '@web/shared-data-access-context'

// TODO: Fold SearchInner back into Search when Context wrapper isn't needed
function Search (): ReactElement {
  const { loading, context } = useUserContext()
  const { pathname, search } = useLocation()
  const initPathname = useMemo(() => pathname, [])
  const [trackInitial, setTrackInitial] = useState(false)

  // look for some param in the query string...
  const params = useMemo(() => new URLSearchParams(search), [search])
  const groupid = useMemo(() => params.get('groupid'), [params])

  useEffect(() => {
    setTrackInitial(true)
  }, [])

  return (
    <Switch>
      <Route path={ROUTES.DiscountedProductsInGroup}>
        {context != null && groupid &&
          <TrackComponentLoad trackInitial={trackInitial}>
            <DiscountedProductsInGroup groupId={groupid} />
          </TrackComponentLoad>}
      </Route>
      <Route path={[ROUTES.Articles, ROUTES.Browse, ROUTES.Search, initPathname]}>
        <TrackComponentLoad trackInitial={trackInitial}>
          <EmbeddedSvgLoader />
          <LoadingIndicator loading={loading}>
            {context != null && <SearchInner />}
          </LoadingIndicator>
        </TrackComponentLoad>
      </Route>
      <Route path='*' render={() => <BrowserRedirect url={pathname + search} addQuerystringHack replace />} />
    </Switch>
  )
}

function SearchInner (): ReactElement | null {
  /**
   * this query is going to get hit for every back button (i.e. will pile up requests and not cancel subsequent calls)
   * one solution would be to introduce a(nother) in-between component that would rerender where the query aborts need to happen
   */
  const searchResultsScaffold: SearchScaffoldViewModel = useMemo(() => window.stullerMetadata?.searchResultsScaffold, [])
  const { pathname, search } = useLocation()
  const usePathname = pathname.startsWith('/') ? pathname.slice(1) : pathname
  const params = new URLSearchParams(search)
  const { loading, data: newData, previousData, error } = useGetSearchNavigationQuery({
    fetchPolicy: 'no-cache',
    variables: {
      pathname: usePathname, // String argument must only be the current path (without leading slash)
      search: params.toString()
    }
  })
  const data = ((loading && newData == null) ? previousData : newData)?.getSearchNavigation
  const { context } = useUserContext()

  // scrool to the top of the page every time the component rerenders
  useEffect(() => {
    window.document.body.scrollIntoView()
  })

  const isDealsOrSalesEvent = useMemo(() => data != null && [
    SearchPresentationContext.Deals,
    SearchPresentationContext.SalesEvent,
    SearchPresentationContext.StullerFirstSale
  ].includes(data.PresentationContext), [data?.PresentationContext])

  const categoryPermalinkId = useMemo(() => {
    if (context != null && data?.CategoryId != null && !data?.IsContentOnlyDisplayTemplate && !isDealsOrSalesEvent) {
      return data.CategoryId
    } else {
      return null
    }
  }, [context, data?.CategoryId, data?.IsContentOnlyDisplayTemplate, isDealsOrSalesEvent])
  const categoryPermalinkUrl = data?.CategoryPermalinkUrl ?? 'http://webadmin.stuller.com/mvc/Category.mvc'

  const queryReturnedBadJson = useMemo(() => error?.networkError?.name === CustomApolloErrorNames.JSON_RESPONSE_TYPE_ERR, [error])
  const isContentOnlyDisplayTemplate = data?.IsContentOnlyDisplayTemplate ?? false

  useEffect(() => {
    if (searchResultsScaffold.pageTitle != null) {
      window.document.title = searchResultsScaffold.pageTitle
    }
  }, [])

  useEffect(() => {
    if (data?.PageTitle != null) {
      window.document.title = data.PageTitle
    }
  }, [data?.PageTitle])

  if (data?.RedirectUrl != null && data.RedirectUrl !== '') {
    return <BrowserRedirect url={data.RedirectUrl} replace />
  }

  return (
    <LoadingIndicator loading={loading}>
      {!loading && data == null && error == null && <NotFoundPage />}
      {error != null && !queryReturnedBadJson && <DynamicErrorPage />}
      {queryReturnedBadJson && <BrowserRedirect url={`${pathname}${search}`} replace addQuerystringHack />}
      <div id='catalog'>
        {categoryPermalinkId != null && <Permalink categoryId={categoryPermalinkId} categoryPermalinkUrl={categoryPermalinkUrl} />}
      </div>
      {data != null &&
        <CmsContentProvider categoryId={data.CategoryId}>
          {!isContentOnlyDisplayTemplate &&
            <ProductNavigationContext.Provider value={data}>
              <ProductNavigationInner navProps={data} scaffold={searchResultsScaffold} />
            </ProductNavigationContext.Provider>}
          {isContentOnlyDisplayTemplate &&
            <ContentOnlyView
              contentId={data.ContentId}
            />}
        </CmsContentProvider>}
    </LoadingIndicator>
  )
}

export { Search }
